<template>
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="background-color: #F7F5F9; overflow: scroll;">
        <a-modal
            :visible="moveModal.visible"
            :footer="false"
            @cancel="onClose"
            @ok="onClose"
            :width="'40%'"
            :bodyStyle="{padding: '40px'}"
            centered
            >
            <BHLoading :show="loading" />
            <template>
                <h4 class="mb-4">Move To</h4>

                <a-form-item label="Which stage do you want to move to?">
                    <a-select v-model="moveStage" class="mt-2" style="width: 100%;" size="large">
                        <a-select-option v-for="(stage,stageI) in settings.options.stages.list" :key="stage+stageI" :value="stage.id">
                            {{stage.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <div class="mt-5" style="width: 100%; text-align: right;">
                    <a-button @click="onClose" class="mr-3" style="width: 100px; height: 35px;">CANCEL</a-button>
                    <a-button @click="moveOpportunity" type="primary" style="width: 100px; height: 35px;">MOVE</a-button>
                </div>
            </template>
        </a-modal>

        <a-modal
            :visible="bulkMove.visible"
            :footer="false"
            @cancel="onClose2"
            @ok="onClose2"
            :width="'40%'"
            :bodyStyle="{padding: '40px'}"
            centered
            >
            <BHLoading :show="loading" />
            <template>
                <h4 class="mb-4">Move To</h4>

                <a-form-item label="Which stage do you want to move to?">
                    <a-select v-model="bulkMove.selectedStage" class="mt-2" style="width: 100%;" size="large">
                        <a-select-option v-for="(stage,stageI) in settings.options.stages.list" :key="stage+stageI" :value="stage.id">
                            {{stage.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <div class="mt-5" style="width: 100%; text-align: right;">
                    <a-button @click="onClose2" class="mr-3" style="width: 100px; height: 35px;">CANCEL</a-button>
                    <a-button @click="moveOpportunities" type="primary" style="width: 100px; height: 35px;">MOVE</a-button>
                </div>
            </template>
        </a-modal>

        <div class="dF jSB">
            <h4 class="mb-3 mt-3">{{sidebarSelection}}</h4>
        </div>

        <template v-if="true">
            <a-card>
                <MoreActions v-if="selectedRowKeys.length" style="position: absolute; z-index: 1; right: 0; margin-top: 1.33rem; margin-right: 1.33rem;" shape="button" buttonText="Bulk Actions" :items="bulkActions" @action="bulkActioned"/>
                <a-tabs class="archive-tabs" v-model="activeKey" default-active-key="1">
                    <a-tab-pane key="1" tab="Won">
                        <div class="tab-content">
                            <a-table :rowKey="(e) => e.id" :columns="columns" :row-selection="rowSelection" :dataSource="won" :pagination="false" class="white-table hide-scrollbar" :scroll="{ x: 1350 }">
                                <div slot="name" slot-scope="obj" class="dF" style="align-items: center; font-weight: bold;">
                                    {{obj.name}}
                                </div>
                                <div slot="dealValue" slot-scope="obj">
                                    ${{numberWithCommas(obj.dealValue)}}
                                </div>
                                <div slot="contact" slot-scope="obj" class="dF" style="align-items: center;">
                                    <div class="mr-3 dF" style="align-items: center;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.563" height="23.5" viewBox="0 0 20.563 23.5" fill="#9ea0a5"> <path id="Icon_awesome-address-book" data-name="Icon awesome-address-book" class="cls-1" d="M20.012,7.344a.552.552,0,0,0,.551-.551V4.957a.552.552,0,0,0-.551-.551h-.918V2.2a2.2,2.2,0,0,0-2.2-2.2H2.2A2.2,2.2,0,0,0,0,2.2V21.3a2.2,2.2,0,0,0,2.2,2.2H16.891a2.2,2.2,0,0,0,2.2-2.2v-2.2h.918a.552.552,0,0,0,.551-.551V16.707a.552.552,0,0,0-.551-.551h-.918V13.219h.918a.552.552,0,0,0,.551-.551V10.832a.552.552,0,0,0-.551-.551h-.918V7.344ZM9.547,5.875A2.938,2.938,0,1,1,6.609,8.813,2.94,2.94,0,0,1,9.547,5.875Zm5.141,10.869a.963.963,0,0,1-1.028.881H5.434a.963.963,0,0,1-1.028-.881v-.881a2.89,2.89,0,0,1,3.084-2.644H7.72a4.728,4.728,0,0,0,3.654,0H11.6a2.89,2.89,0,0,1,3.084,2.644Z"/> </svg>
                                    </div>
                                    {{obj.contact.firstName + ' ' + obj.contact.lastName}}
                                </div>
                                <div slot="closeDate" slot-scope="obj">
                                    {{convertDate(obj.closeDate)}}
                                </div>
                                <div slot="owners" slot-scope="obj" class="dF">
                                    <template v-for="(user,userI) in obj.owners">
                                        <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                                            <a-avatar v-if="user.avatar && user.avatar !== ''" :src="user.avatar" shape="circle" :size="30"/>
                                            <a-avatar v-else shape="circle" :size="30">{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                                        </div>
                                    </template>
                                </div>
                                <div slot="actions" slot-scope="obj" class="dF jE" style="color: #9EA0A5;">
                                    <div class="mr-4 dF" style="align-items: center;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                                        <div class="ml-2">
                                            {{obj.notes.length || 0}}
                                        </div>
                                    </div>
                                    <div class="dF" style="align-items: center;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.563 15.5" fill="#9ea0a5"> <path id="Icon_awesome-paperclip" data-name="Icon awesome-paperclip" class="cls-1" d="M1.309,14.112a4.743,4.743,0,0,1,.042-6.587l6.35-6.5a3.388,3.388,0,0,1,4.864,0,3.524,3.524,0,0,1,0,4.913L7.03,11.6a2.259,2.259,0,0,1-3.269-.03A2.346,2.346,0,0,1,3.8,8.331L8.156,3.885a.484.484,0,0,1,.685-.007l.692.677a.484.484,0,0,1,.007.685L5.19,9.685a.409.409,0,0,0-.02.554.323.323,0,0,0,.475,0L11.18,4.587a1.586,1.586,0,0,0,0-2.2,1.452,1.452,0,0,0-2.094,0l-6.35,6.5A2.8,2.8,0,0,0,2.7,12.764a2.582,2.582,0,0,0,3.725.009l5.209-5.328a.484.484,0,0,1,.685-.008l.693.677a.484.484,0,0,1,.008.685L7.811,14.126a4.517,4.517,0,0,1-6.5-.015Z"/> </svg>
                                        <div class="ml-2">
                                            {{0}}
                                        </div>
                                    </div>
                                </div>
                                <div slot="more" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                                    <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                                        <div slot="content">
                                            <div @click="moveArchived(obj)" class="popoverContent dF aC"><a-icon type="export" class="mr-2" /><div>Move to</div></div>
                                            <div @click="deleteArchived(obj)" class="popoverContent dF aC"><svg style="color:#FD647C" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg><div>Delete</div></div>
                                        </div>
                                        <div class="more-option-icon mr-4">
                                            <a-icon style="line-height: 40px;" type="more" />
                                        </div>
                                    </a-popover>
                                </div>
                            </a-table>
                        </div>
                    </a-tab-pane>

                    <a-tab-pane key="2" tab="Lost">
                        <div class="tab-content">
                            <a-table :rowKey="(e) => e.id" :columns="columns" :row-selection="rowSelection" :dataSource="lost" :pagination="false" class="white-table hide-scrollbar" :scroll="{ x: 1350 }">
                                <div slot="name" slot-scope="obj" class="dF" style="align-items: center; font-weight: bold;">
                                    {{obj.name}}
                                </div>
                                <div slot="dealValue" slot-scope="obj">
                                    ${{numberWithCommas(obj.dealValue)}}
                                </div>
                                <div slot="contact" slot-scope="obj" class="dF" style="align-items: center;">
                                    <div class="mr-3 dF" style="align-items: center;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.563" height="23.5" viewBox="0 0 20.563 23.5" fill="#9ea0a5"> <path id="Icon_awesome-address-book" data-name="Icon awesome-address-book" class="cls-1" d="M20.012,7.344a.552.552,0,0,0,.551-.551V4.957a.552.552,0,0,0-.551-.551h-.918V2.2a2.2,2.2,0,0,0-2.2-2.2H2.2A2.2,2.2,0,0,0,0,2.2V21.3a2.2,2.2,0,0,0,2.2,2.2H16.891a2.2,2.2,0,0,0,2.2-2.2v-2.2h.918a.552.552,0,0,0,.551-.551V16.707a.552.552,0,0,0-.551-.551h-.918V13.219h.918a.552.552,0,0,0,.551-.551V10.832a.552.552,0,0,0-.551-.551h-.918V7.344ZM9.547,5.875A2.938,2.938,0,1,1,6.609,8.813,2.94,2.94,0,0,1,9.547,5.875Zm5.141,10.869a.963.963,0,0,1-1.028.881H5.434a.963.963,0,0,1-1.028-.881v-.881a2.89,2.89,0,0,1,3.084-2.644H7.72a4.728,4.728,0,0,0,3.654,0H11.6a2.89,2.89,0,0,1,3.084,2.644Z"/> </svg>
                                    </div>
                                    {{obj.contact.firstName + ' ' + obj.contact.lastName}}
                                </div>
                                <div slot="closeDate" slot-scope="obj">
                                    {{convertDate(obj.closeDate)}}
                                </div>
                                <div slot="owners" slot-scope="obj" class="dF">
                                    <template v-for="(user,userI) in obj.owners">
                                        <div v-if="userI < 3" :key="userI" class="owner-icon" :style="userI !== 0? 'margin-left: -5px' : ''">
                                            <a-avatar v-if="user.avatar && user.avatar !== ''" :src="user.avatar" shape="circle" :size="30"/>
                                            <a-avatar v-else shape="circle" :size="30">{{user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()}}</a-avatar>
                                        </div>
                                    </template>
                                </div>
                                <div slot="actions" slot-scope="obj" class="dF jE" style="color: #9EA0A5;">
                                    <div class="mr-4 dF" style="align-items: center;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12.048 12.049" fill="#9ea0a5"> <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" class="cls-1" d="M10.542,0H1.506A1.507,1.507,0,0,0,0,1.506V8.283A1.507,1.507,0,0,0,1.506,9.789H3.765v1.977a.283.283,0,0,0,.449.228l2.939-2.2h3.388a1.507,1.507,0,0,0,1.506-1.506V1.506A1.507,1.507,0,0,0,10.542,0Z"/> </svg>
                                        <div class="ml-2">
                                            {{obj.notes.length || 0}}
                                        </div>
                                    </div>
                                    <div class="dF" style="align-items: center;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 13.563 15.5" fill="#9ea0a5"> <path id="Icon_awesome-paperclip" data-name="Icon awesome-paperclip" class="cls-1" d="M1.309,14.112a4.743,4.743,0,0,1,.042-6.587l6.35-6.5a3.388,3.388,0,0,1,4.864,0,3.524,3.524,0,0,1,0,4.913L7.03,11.6a2.259,2.259,0,0,1-3.269-.03A2.346,2.346,0,0,1,3.8,8.331L8.156,3.885a.484.484,0,0,1,.685-.007l.692.677a.484.484,0,0,1,.007.685L5.19,9.685a.409.409,0,0,0-.02.554.323.323,0,0,0,.475,0L11.18,4.587a1.586,1.586,0,0,0,0-2.2,1.452,1.452,0,0,0-2.094,0l-6.35,6.5A2.8,2.8,0,0,0,2.7,12.764a2.582,2.582,0,0,0,3.725.009l5.209-5.328a.484.484,0,0,1,.685-.008l.693.677a.484.484,0,0,1,.008.685L7.811,14.126a4.517,4.517,0,0,1-6.5-.015Z"/> </svg>
                                        <div class="ml-2">
                                            {{0}}
                                        </div>
                                    </div>
                                </div>
                                <div slot="more" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                                    <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                                        <div slot="content">
                                            <div @click="moveArchived(obj)" class="popoverContent dF aC"><a-icon type="export" class="mr-2" /><div>Move to</div></div>
                                            <div @click="deleteArchived(obj)" class="popoverContent dF aC"><svg style="color:#FD647C" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg><div>Delete</div></div>
                                        </div>
                                        <div class="more-option-icon mr-4">
                                            <a-icon style="line-height: 40px;" type="more" />
                                        </div>
                                    </a-popover>
                                </div>
                            </a-table>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </a-card>
        </template>

        <template v-else>
            <EmptyApp :app="$store.state.theApp" />
        </template>
    </div>
</template>

<script>
    import {getBase64} from 'bh-mod'
    import AEmpty from 'ant-design-vue/lib/empty'
    import BHLoading from 'bh-mod/components/common/Loading'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    import MoreActions from 'bh-mod/components/common/MoreActions'

    export default {
        components:{
            AEmpty,BHLoading,EmptyApp,MoreActions
        },
        data() {
            return {
                selectedRowKeys: [],
                bulkActions:[
                    {
                        label:'Move to',
                        value:'move'
                    },
                    {
                        label:'Delete',
                        value:'delete'
                    }
                ],

                activeKey:'1',
                moveModal:{
                    opportunity:{},
                    visible:false,
                },

                bulkMove:{
                    visible:false,
                    selectedStage:'won'
                },

                refresh:0,
                moveStage:'won',
                loading:false,

                columns: [
                    { title: 'Name', key: 'name', scopedSlots: { customRender: 'name' }},
                    { title: 'Value', key: 'dealValue', scopedSlots: { customRender: 'dealValue' }},
                    { title: 'Related To', key: 'contact', scopedSlots: { customRender: 'contact' }},
                    { title: 'Archive Date', key: 'closeDate', scopedSlots: { customRender: 'closeDate' }},
                    { title: 'Owner(s)', key: 'owners', scopedSlots: { customRender: 'owners' }},
                    { title: '', key: 'actions', scopedSlots: { customRender: 'actions' }},
                    { title: '', key: 'more', scopedSlots: { customRender: 'more' }},
                ],
            }
        },
        watch:{

        },
        computed:{
			dateFormat(){
				return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
			},
            rowSelection() {
                const { selectedRowKeys } = this;
                return {
                    selectedRowKeys,
                    onChange: this.onSelectChange,
                    hideDefaultSelections: false,
                    onSelection: this.onSelection,
                };
            },
            won(){
                let refresh = this.refresh
                let won = []
                this.opportunities.forEach( x => {
                    if (x.stage == 'won' && x.archived){
                        won.push(x)
                    }
                })
                return won
            },
            lost(){
                let refresh = this.refresh
                let lost = []
                this.opportunities.forEach( x => {
                    if (x.stage == 'lost' && x.archived){
                        lost.push(x)
                    }
                })
                return lost
            },
            opportunities(){
                return this.$store.state.contacts.opportunities
            },
            sidebarSelection(){
                return this.$store.state.sidebarSelection
            },
            tags(){
                return this.$store.state.contacts.tags
            },
            searchOBJ(){
                return this.$store.state.contacts.searchOBJ
            },
            resultLength(){
                return this.$store.state.contacts.resultLength
            },
            showingContactMode(){
                return this.listMode === 'all' ? `Showing All Contacts (${this.resultLength} Contacts) `: `Found ${this.resultLength} Contacts `
            },
            newStoreTime(){
                return this.$store.state.contacts.newStoreTime
            },
            tasks(){
                return this.$store.state.contacts.tasks
            },
            appointments(){
                return this.$store.state.contacts.appointments
            },
            instance(){
                return this.$store.state.instance
            },
            settings(){
                return this.$store.state.contacts.allSettings.app
            },
            query(){
                return this.$store.getters.query
            },
            loadingPage:{
                get(){
                    return this.$store.state.contacts.loadingPage
                },
                set(val){
                    this.$store.commit('LOAD_PAGE',val)
                }
            }
        },
        methods:{
            onClose(){
                this.moveModal = {
                    opportunity:{},
                    visible:false
                }
                this.moveStage = 'won'
            },
            onClose2(){
                this.bulkMove = {
                    selectedStage:'won',
                    visible:false
                }
            },
            moveOpportunity(){
                this.loading = true
                let sendObj = JSON.parse(JSON.stringify(this.moveModal.opportunity))
                sendObj.archived = false
                sendObj.stage = this.moveStage
                this.$api.put(`/opportunities/:instance/${sendObj.id}`,sendObj).then( ({data}) => {
                    let index = this.opportunities.findIndex(item => item.id == data.id)
                    this.opportunities[index] = data
                    this.refresh = Date.now()
                    this.loading = false
                    this.onClose()
                }).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            },
            moveOpportunities(){
                this.loading = true

                const finishLoading = () => {
                    this.loading = false
                    this.bulkMove.visible = false
                    this.bulkMove.selectedStage = 'won'
                    this.refresh = Date.now()
                    this.timerId  =  undefined;
                    this.selectedRowKeys = []
                }

                this.selectedRowKeys.forEach( x => {
                    if (this.activeKey == '1'){
                        let sendObj = JSON.parse(JSON.stringify(this.won[x]))
                        sendObj.archived = false
                        sendObj.stage = this.bulkMove.selectedStage

                        this.$api.put(`/opportunities/:instance/${sendObj.id}`,sendObj).then( ({data}) => {
                            let index = this.opportunities.findIndex(item => item.id == data.id)
                            this.opportunities[index] = data

                            if (this.timerId)  clearTimeout(this.timerId)

                            this.timerId  =  setTimeout(function () {
                                finishLoading()
                                this.timerId = undefined;
                            }, 500)
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else {
                        let sendObj = JSON.parse(JSON.stringify(this.lost[x]))
                        sendObj.archived = false
                        sendObj.stage = this.bulkMove.selectedStage

                        this.$api.put(`/opportunities/:instance/${sendObj.id}`,sendObj).then( ({data}) => {
                            let index = this.opportunities.findIndex(item => item.id == data.id)
                            this.opportunities[index] = data

                            if (this.timerId)  clearTimeout(this.timerId)

                            this.timerId  =  setTimeout(function () {
                                finishLoading()
                                this.timerId = undefined;
                            }, 500)
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                })
            },
            convertDate(num){
                let x = new Date(num)
                //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
                let year = x.getFullYear()
                let month = x.getMonth()+1
                let day = x.getDate()
				if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
				else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
				else {
					return month+'/'+day+'/'+year
				}
            },
            async bulkActioned({key,keyPath,val}){
                let self = this
                if (key == 'move'){
                    this.bulkMove.visible = true
                }
                if (key == 'delete') {
                    if (this.$p < 40){
                        return this.$message.error('You do not have permission to delete Opportunities')
                    }
                    this.$confirm({
                        title: "Delete",
                        content: h => <div>Do you want to delete these opportunities?</div>,
                        okText: 'Delete',
                        okType: 'danger',
                        cancelText: 'Cancel',
                        centered: true,
                        onOk() {
                            let opportunities = JSON.parse(JSON.stringify(self.opportunities))

                            const finishLoading = () => {
                                self.$store.commit('UPDATE_OPPORTUNITIES',opportunities)
                                self.refresh = Date.now()
                                self.selectedRowKeys = []
                            }

                            self.selectedRowKeys.forEach( x => {
                                if (self.activeKey == '1'){
                                    self.$api.delete(`/opportunities/:instance/${self.won[x].id}`).then( ({data}) => {
                                        self.$store.commit('UPDATE_OPPORTUNITIES', data)
                                        if (self.timerId)  clearTimeout(self.timerId)

                                        self.timerId  =  setTimeout(function () {
                                            finishLoading()
                                            self.timerId = undefined;
                                        }, 500)
                                    }).catch(err => {
										if (!err || !err.response || !err.response.status || err.response.status !== 400) {
											self.$message.error(self.$err(err))
										}
									})
                                } else {
                                    self.$api.delete(`/opportunities/:instance/${self.lost[x].id}`).then( ({data}) => {
                                        self.$store.commit('UPDATE_OPPORTUNITIES', data)

                                        if (self.timerId)  clearTimeout(self.timerId)

                                        self.timerId  =  setTimeout(function () {
                                            finishLoading()
                                            self.timerId = undefined;
                                        }, 500)
                                    }).catch(err => {
										if (!err || !err.response || !err.response.status || err.response.status !== 400) {
											self.$message.error(self.$err(err))
										}
									})
                                }
                            })
                        },
                        onCancel() {
                            console.log('Cancel')
                        }
                    })
                }
            },
            moveArchived(obj){
                this.moveModal = {
                    opportunity:obj,
                    visible: true,
                }
            },
            deleteArchived(obj){
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete Archived')
                }
                let self = this
                this.$confirm({
                    title: "Delete Opportunity",
                    content: h => <div>Do you want to delete <b>{obj.name}</b>? All information related to them will be lost.</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.$api.delete(`/opportunities/:instance/${obj.id}`).then( ({data}) => {
                            self.$store.commit('UPDATE_OPPORTUNITIES', data)
                            self.refresh = Date.now()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            },
            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            removeSearch({srch,searchKey}){
                this.$store.commit('REMOVE_SEARCH_PARAM',{srch,searchKey})
            },
            onSelectChange(selectedRowKeys) {
                this.selectedRowKeys = selectedRowKeys;
            },

        },
        created() {
            this.$store.commit('SIDEBAR_SELECTION', {value:'Archived'})

        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>

    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:5%;
        }
    }
    .aB{
        align-items: baseline;
    }
    .edit-delete {
        padding: 9px 0;
		cursor: pointer;
		color: #9EA0A5;
		transition:color .1s ease-in;
	}
	.edit-delete:hover {
		color: orange;
		transition:color .1s ease-in;
	}
    .more-option-icon{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
        color: var(--orange) !important;
        cursor: pointer;
        background-color: var(--light-gray);
	}

    .popoverContent{
        height: 35px;
        width: 100px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .smallIcon {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        padding: 7px 0;
    }

</style>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .card-headers .ant-card-body {
        padding: 15px 25px;
    }
    .archive-tabs .ant-tabs-nav {
        font-size: 18px;
        .ant-tabs-tab {
            padding-left: 18px;
            padding-right: 18px;
        }
    }
</style>
